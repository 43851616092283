<template>
  <div class="Vendor-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <ReportViewBar v-if="Vendor" :title="`Vendor List`" :start="start" :end="end" />

        <v-container fluid>
          <table class="center">
            <tbody class="report-container">
              <v-card-text>
                <v-data-table dense sort-by="date" readonly :headers="headers" :itemsPerPage="Vendor.length"
                  :sort-by="sortBy" hide-default-footer :items="Vendor">
                  <template v-slot:item="{ item }">
                    <tr>
                      <td class="text-left">
                        {{ item.createdAt | moment("MM/DD/YYYY") }}
                      </td>
                      <td class="text-left text-capitalize">
                        {{ item.name }}
                      </td>
                      <td class="text-left">
                        {{ item.address }}
                      </td>
                    </tr>
                  </template>
               
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import ReportViewBar from "@/components/ReportViewBar";
// import CategoryService from "@/modules/Category/service";
import VendorService from "@/modules/Inventory/Vendor/service.js";
// import Hashids from "hashids";
// const hashids = new Hashids();

export default {
  data() {
    return {
      start: null,
      end: null,
      isLoading: true,
      Vendor: [],
      sortBy: "createdAt",
      headers: [
        {
          text: "DATE",
          align: "left",
          value: "createdAt",
          sortable: true,
        },
        {
          text: "NAME",
          align: "left",
          value: "localId",
          sortable: true,
        },

        {
          text: "ADDRESS",
          align: "left",
          value: "vendor.name",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    total() {
      let total = 0;
      this.Vendor.map((row) => {
        total += row.total;
      });
      return total;
    },
    due() {
      let total = 0;
      this.Vendor.map((row) => {
        total += row.due;
      });
      return total;
    },
  },
  async created() {
    this.start = this.$route.query.start;
    this.end = this.$route.query.end;
    await this.getAll();
  },
  components: { ReportViewBar },
  methods: {
    viewVendor(Vendor) {
      const routeData = this.$router.resolve({
        path: `/global/Vendor/${Vendor.id}/view`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    getAll() {
      // let where = {
      //   start: this.start,
      //   end: this.end,
      // };

      return VendorService.getAll().then((response) => {
        console.log("response VendorService", response);
        this.$Progress.finish();
        this.Vendor = response.data;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .no-print {
    display: none;
  }

  .detailBox {
    overflow: visible;
    height: 100%;
  }

  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}

.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}

.center {
  background-color: white;
  margin: auto;
  width: 100%;
}

/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
